import React, {  Suspense } from "react";
import { Routes,Route, BrowserRouter } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import "toastr/build/toastr.min.css";
import DashBoard from './component/dashboard';
import LoginPage from './component/login/LoginPage';
import { Loader } from "./component/sofbox";
import TravelWebsite from './component/sample';

function App() {

  return (
    <div className="App">
       <Suspense fallback={<Loader />}>
        <BrowserRouter> 
                <Routes>
                    <Route path="/" element={<DashBoard/>} />
                    <Route path="/about" element={<TravelWebsite/>} />
                    <Route path="/login" element={<LoginPage/>} />
                </Routes>
        </BrowserRouter>
      </Suspense>      
    </div>
  );
}

export default App;
